
const refreshPlayer = (player, getPlayer, setPlayers) => {

  getPlayer(player.name)
  .then(p => {
    if (p.overall){

      setPlayers((playersUseState)=>{
        
        const copyListItems = [...playersUseState];

        // TIENE QUE ORDERNAR POR LA SUMA DE LOS KDs PARCIALES Y NO POR EL TOTAL. 
        copyListItems.sort((a,b)=>{
          if (a.overall.kd > b.overall.kd) {
            return 1;
          }
          if (a.overall.kd < b.overall.kd) {
            return -1;
          }
          return 0;
        });
        copyListItems.reverse();
        const kdPlayer = copyListItems[0];
        const kdPlayerPlata = copyListItems.length > 1 ? copyListItems[1] : false; 
        const kdPlayerBronce = copyListItems.length > 2 ? copyListItems[2] : false; 

        const t = playersUseState.map((pus)=>{
          const result = pus.name !== p.name ? pus : p;
          result.kd = kdPlayer.name === pus.name;
          result.kdPlata = kdPlayerPlata.name === pus.name;
          result.kdBronce = kdPlayerBronce.name === pus.name;                  
          return result;
        });

        localStorage.setItem('playersV2', JSON.stringify(t));
        return t;
      });
    }
  })   

}

export default refreshPlayer