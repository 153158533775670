import React from 'react';

const Marker = ({ left, top, text }) => {
  const markerStyle = {
    position: 'absolute',
    left: `${left}px`,
    top: `${top}px`,
    transform: 'translate(-40%, 50%)',
    backgroundColor: 'green',
    textAlign: 'center',
    textweight: 'bold',
    color: 'white',
    borderRadius: '5px',
    padding: '2px 6px',
    zIndex: 999,

    fontSize: '.6vw'
  };

  return <div style={markerStyle}>{text}</div>;
};

export default Marker;