import React, { useEffect, useState, useRef } from 'react'
import getMap from '../../helpers/getMap';
import Marker from './Marker';

export const Map = () => {

  const [mapData, setMapData] = useState(null);
  const imgMapRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [poislist, setPoislist] = useState(null);

  const worldRadius = 135000;

  useEffect(() => { 
    getMap()
    .then(data => {
      setMapData(data);
    });
  }, []) 
  
  const calculateLocation = (poiX, poiY) => {
    const imgWidth = imgMapRef.current.width;
    const imgHeight = imgMapRef.current.height;

    var mx = (poiY + worldRadius) / (worldRadius * 2) * imgWidth;
    var my = (1 - (poiX + worldRadius) / (worldRadius * 2)) * imgHeight;

    return { x: mx, y: my};
  } 

  const calculatePois = ()=>{
    const pois = mapData.pois;

    const poiListFiltered = pois.filter((poi, index) => {
      return poi.name !== null;
    }); 

    const poisList = poiListFiltered.map((poi, index) => {
      const {x, y} = calculateLocation(poi.location.x, poi.location.y);
      return <Marker key={index} text={poi.name} left={x} top={y} />
    }) ;

    setPoislist(poisList);
  }

  useEffect(() => { 
    if (imageLoaded && imgMapRef.current) {
      calculatePois();
    }
   }, [imageLoaded])  


   useEffect(() => {
    const handleResize = () => {
      if (mapData.pois){
        calculatePois();
      }
  
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    console.log(offsetX, offsetY);
  };

  return (
    <div className='map-container'>
        <img onLoad={() => setImageLoaded(true)} ref={imgMapRef} id="imgMap" onClick={handleClick} src={mapData ? mapData.images.blank : ''} alt='mapa' className="responsive-img"/>  
        {/* <Marker text="mi texto" left={markerPosition.left} top={markerPosition.top} /> */}

        {poislist}

    </div>
  )
}
