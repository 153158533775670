 const getMap = async () => {
    const url = `https://fortnite-api.com/v1/map?language=es`;
    
    try {
      const resp = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': '3abe294f-918e-45d8-86de-1525f37b3acc'
        }
      });

      const {data} = await resp.json();
    
      return data;

    } catch (error) {
        console.log(error);
    } 

    return null;
}

export default getMap