import React from 'react'
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AddPlayer from './components/AddPlayer';
import { GridPlayers } from './components/GridPlayers/GridPlayers';
import useStatePlayer from './hooks/useStatePlayer';
import { Statitiscs } from './components/Statitiscs/Statitiscs';
import { Map } from './components/map/Map';

export default function FortniteApp() {

  const MAIN = "main";
  const STATS = "stats";
  const MAP = "map";

  const MAXI = "Completo";
  const MINI = "Resumen";

  const [view, setView] = useState(MAIN);
  const [ players, setPlayers, sizeCards, setSizeCards ] = useStatePlayer();

  const handleSizeCards = (e) => {
    e.preventDefault();

    const newValue = sizeCards === "L"? "S":"L";
    setSizeCards(newValue);
    localStorage.setItem('sizeCards', newValue);
  }
  
  const handleStstistics = (e) => {
    e.preventDefault();

    setView(view === MAIN ? STATS : MAIN);
    
  }

  const handleMap = (e) => {
    e.preventDefault();

    setView(view === MAIN ? MAP : MAIN);
    
  }
  return (
    <>
        <h2 className='title_app'>Fortnite Comparador<br/><span className='subtitle_app'>by MarcGalarga</span></h2>
        <hr/>

        {
          view === MAP 
          ? 
            // STATS ////////////////////////////////////////////////////////////////////////////
            <Container fluid>
              <Row>
                <Col md={12} lg={'auto'}>
                  <button id="buttonMap" className='btn btn-primary' onClick={handleMap}>Volver</button>
                </Col> 
              </Row>
              <Row >
                <Col xd={12} >
                  <Map/>
                </Col>
              </Row>
            </Container>
          : 
            view === STATS
            ? 
              // STATS ////////////////////////////////////////////////////////////////////////////
              <Container fluid>
                <Row>
                  <Col md={12} lg={'auto'}>
                    <button id="buttonStatiscs" className='btn btn-primary' onClick={handleStstistics}>Volver</button>
                  </Col> 
                </Row>
                <Row >
                  <Col xd={12} >
                    <Statitiscs/>
                  </Col>
                </Row>
              </Container>
            : 

              // MAIN ////////////////////////////////////////////////////////////////////////////
              <Container fluid>
                <Row>
                  <Col md={12} lg={'auto'}>
                    <AddPlayer setPlayers = {setPlayers}/>
                  </Col> 
                  <Col md={12} lg={'auto'}>
                    <button id="buttonSizeCards" className='btn btn-primary' onClick={handleSizeCards}>{sizeCards === "S"? MINI:MAXI}</button>
                    <button id="buttonStatiscs" className='btn btn-primary' onClick={handleStstistics}>Estadísticas</button>
                    <button id="buttonMap" className='btn btn-primary' onClick={handleMap}>Mapa</button>
                  </Col>   
                  {/* <Col md={12} xs={3} lg={'auto'}>
                    <button className='btn btn-primary'>TXT</button>
                  </Col>      */}
                </Row>
                <Row >
                  <Col xd={12} >
                    <GridPlayers players={players} setPlayers={setPlayers} sizeCards={sizeCards} />
                  </Col>
                </Row>
              </Container>                

        }

    </>
  )
}

